
export default {
    global: {
        use_btn: 'Get Started',
        free_btn: 'Free Trial',
        open_btn: 'Buy Now',
        enter_btn: 'Workspace',
        outLog_btn: 'Log Out',
        login_btn: 'Registration/Login'
    },
    //header
    header_config: {
        product: {
            title: 'Product',
            content: [
                {
                    card_tit: 'ChatExcel',
                    card_cont: 'You can control excel  just by chatting'
                },
                {
                    card_tit: 'AI VOC',
                    card_cont: 'Use AI to mine data for conversational product/consumer/market insights'
                },
                {
                    card_tit: 'AI customer service',
                    card_cont: 'Full channel, full process coverage, to provide intelligent and personalized services'
                }
            ],

        },

        price: 'Price',
        tutorials: 'Tutorials',
    },
    // footer
    footer_config: {
        information: '扫码添加客服微信，关注公众号',
        about_us: 'About us',
        introduce: 'Company introduction',
        update_dynamic: 'Update dynamic',
        update_log: 'Update log'
    },
    // 主页
    startPage: {
        top_title: {
            title: 'Manage Your Spreadsheets with',
            title_color: 'Simple Chat',
            top_speak: 'ChatExcel does what you say',
            illustrate: 'Choice for 2000w+ users worldwide',
            top_gif: 'https://qiniu.yuankongai.com/20240927-202850.gif'
        },
        center_config: {
            tit1: 'ChatExcel automates the processing of tables',
            tit_color: 'in a chat manner',
            tit2:''
        },
        tabs_data: [
            { id: '0', title: 'Batch processing to reduce duplication of work', content: 'ChatExcel can process large amounts of data quickly, batch processing and automation of data, reducing repetitive work.' },
            { id: '1', title: 'Intelligent understanding, alternative function processing', content: 'ChatExcel realizes the rapid analysis and mining of data, replaces function processing, and provides users with more intelligent data processing services.' },
            { id: '2', title: 'Multimodal recognition, data visualization', content: 'ChatExcel visualizes data and helps users understand it more intuitively with cool charts.' },
        ],
        area_data: [
            { id: '0', title: 'Section 1 Title', img_url: 'answer1' },
            { id: '1', title: 'Section 2 Title', img_url: 'answer2' },
            { id: '2', title: 'Section 3 Title', img_url: 'answer3' },
        ],
        price_config: {
            tit1: 'Our Pricing',
            tit_color: '',
            price_p1: 'For the price of ',
            price_p2: '',
            price_p3: ', AI can do your ',
            price_p4: '',
            price_color1: ' a cup of coffee',
            price_color2: ' work for 30 days',
            priceList: [
                {
                    id: 1,
                    title: 'Free',
                    symbol:'$',
                    price: '0',
                    date:'',
                    priceold: 'Free trial for 3 days',
                },
                {
                    id: 2,
                    title: 'Monthly VIP',
                    symbol:'$',
                    price: '9.9',
                    date:'month',
                    priceold: 'Original price 29/month',
                },
                {
                    id: 3,
                    title: 'One year VIP',
                    symbol:'$',
                    price: '99',
                    date:'year',
                    priceold: 'Original price 348/year',
                },
                {
                    id: 4,
                    title: 'Half a year VIP',
                    symbol:'$',
                    price: '79',
                    date:'half a year',
                    priceold: 'Original price 174/half a year',
                }
            ],
        },
        recommend_config: {
            tit: 'They all recommend',
            tit_color: 'ChatExcel',
            commentList: [
                {
                    avatar: 'avatar1',
                    name: "Workplace veteran",
                    comment:
                        "Cool Table ChatExcel is eye-catching and a godsend for professionals, saving time, effort, and worry",
                },
                {
                    avatar: 'avatar2',
                    name: "Mr. Admin Palace",
                    comment:
                        "You developed the ChatExcel website, which is very cool. It uses natural language to describe requirements and machines to perform table operations, which is very suitable for the needs of our entire industry.",
                },
                {
                    avatar: 'avatar3',
                    name: "Manager",
                    comment:
                        "I only have four words to say, thank you. Thank you for your hard work and dedication to our cousin who is still working hard on the front line. We look forward to your early liberation.",
                },
                {
                    avatar: 'avatar4',
                    name: "Teacher Wang",
                    comment:
                        "Cool ChatExcel, once you enter a sentence, it will automatically run and give me the desired result. For daily work tables, the processing effect is amazing, it's really great!",
                },
                {
                    avatar: 'avatar5',
                    name: "Market Manager",
                    comment:
                        "The value of tools is to improve efficiency and enhance user experience. ChatExcel greatly reduces the threshold for using Excel, allowing those of us who are not good at Excel to process data quickly and accurately.",
                },
                {
                    avatar: 'avatar6',
                    name: "Primary school teacher",
                    comment:
                        "Sorting out tables of various grades allows for simple calculations and data organization without the need for functions. There are many students and exams, but teachers also have many treasures",
                },
                {
                    avatar: 'avatar7',
                    name: "Customer service",
                    comment: "For beginners, it is easy to use, and for customer service, the dialogue format is more convenient for handling forms.",
                },
                {
                    avatar: 'avatar8',
                    name: "HR specialist",
                    comment:
                        "Not long after joining, I struggled to organize employee information in spreadsheets due to a lack of Excel skills. However, using ChatExcel does not require technical background and can efficiently integrate information.",
                },
                {
                    avatar: 'avatar9',
                    name: "Data entry personnel",
                    comment:
                        "ChatExcel can simultaneously delete batch cell contents, which greatly reduces my workload. Get off work early!",
                },
                {
                    avatar: 'avatar10',
                    name: "college student",
                    comment:
                        "Special forces style tourism planning and budget management can be achieved in just a few words, truly my special forces team! And the key is free, free, free! This wool is really fragrant.",
                },
                {
                    avatar: 'avatar11',
                    name: "Oral Medical Administrator",
                    comment:
                        "I used to use command+F to search for specific patient information, click on them one by one, delete and modify them, and use chatexcel to help me complete the changes in one sentence",
                },
            ],
        },
  
    },
     // 选择套餐弹窗
     priceDialogPage:{
        dialog_title:'Choose a package',
        member_type:'Membership type',
        member_type_list:[
            {
                name:'ChatExcel Package'
            }
        ],
        purchase_duration:'Purchase duration',
        payment_order:'payment',
        actual_payment:'Amount',
        pay_type:'Payment method',
        agreement:'Payment is deemed consent',
        original_price: 'cost price',
        priceold_unit:'$',
        symbol: '$',
    }
}
