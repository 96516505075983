//JS使用正则表达式校验电话号码
export function checkModbile(mobile) {
    var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
    var result = re.test(mobile);
    if (!result) {
        return false;//若手机号码格式不正确则返回false
    }
    return true;
}
export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var result = re.test(email);
    if (!result) {
        return false;//若手机号码格式不正确则返回false
    }
    return true;
}