<template>
  <div>
    <navHeader></navHeader>
    <router-view />
    <myFooter />
  </div>
</template>

<script>
import navHeader from "@/components/navHeader";
import myFooter from "@/components/myFooter";
export default {
  components: { navHeader, myFooter },
};
</script>

<style>
</style>